import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'



export function list(params) {
	return loginRequest({
    url: '/frontend/website/search/index',
		method: 'post',
		data:Qs.stringify(params)
	})
}